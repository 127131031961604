<template>
    <div v-if="hasSingleOption && options.length > 0">
        <div class="acte_option no-gutters row align-items-center">
            <div class="col-auto pr-2"><font-awesome-icon v-if="icon" :icon="icon" /></div>
            <div class="col"><input type="text" v-model="options[0].val" :placeholder="$t('question.ex_question')" ref="option_input"></div>
        </div>
    </div>

    <div v-else-if="hasOptions">
        <!-- Les réponses possibles -->
        <div v-for="option in options" :key="`option-${option.id}`" class="acte_option no-gutters row align-items-center">
            <div class="col-auto pr-2"><font-awesome-icon v-if="icon" :icon="icon" /></div>
            <div class="col"><input type="text" v-model="option.val" :placeholder="$t('question.ex_question')" ref="option_input"></div>
            <div class="col-auto"><button class="ml-2 btn btn-link" @click="deleteOption(option)"><font-awesome-icon :icon="['fal', 'times']" /></button></div>
        </div>

        <!-- Le champ cliquable pour ajour une réponse possible -->
        <div class="acte_option new_option no-gutters row align-items-center">
            <div class="col-auto pr-2"><font-awesome-icon :icon="['fal', 'plus']" /></div>
            <div class="col"><input type="text" placeholder="Ajouter une autre option" v-model="ajout_option_text"></div>
        </div>
    </div>

    <div v-else>
        <div class="acte_option new_option no-gutters row align-items-center">
            <div class="col-auto pr-2"><font-awesome-icon v-if="icon" :icon="icon" /></div>
            <div class="col"><input type="text" :placeholder="placeholderExample" readonly></div>
        </div>
    </div>
</template>

<script type="text/javascript">

	export default {
		name: "ManageValues",
        props: [
            'type',
            'values',
            'icon'
        ],
        data() {
            return {
                options: [],
                options_index: 0,
                ajout_option_text: '',
                option_to_focus: '',

                // Les types d'inputs ayant des options prédéfinies à renseigner
                types_with_options: ["MSL", "SEL", "BOO", "WBS", "MCQ"],

                // Les types d'inputs n'ayant obligatoirement qu'une option possible (une checkbox seule par ex)
                types_with_single_option: ["BOO", "WBS"],

                // Les types d'inputs n'ayant pas d'options prédéfinies à renseigner - Correspondance entre type d'input et placeholder de l'input static
                types_correspondance: {
                    "DAT": "ex_reponse_date",
                    "NUM": "ex_reponse_int",
                    "TXT": "ex_reponse_str",
                    "LTX": "ex_reponse_long",
                }
            }
        },

        mounted() {
            this.init_component()
        },

        updated() {
            // A chaque update du dom, on regarde si on a un input à focus (si le user a ajouté une option par ex)
            if(this.option_to_focus !== '' && this.option_to_focus !== undefined) {
                this.$refs['option_input'][this.option_to_focus].focus()
                this.option_to_focus = ''
            }
        },

        methods: {
            init_component() {
                if(this.values && this.values.length > 0) {
                    this.values.forEach(value => {
                        this.options.push({ id: this.options_index, val: value })
                        this.options_index += 1
                    })
                }
                else {
                    this.options.push({ id: this.options_index, val: ''})
                    this.options_index += 1
                }
            },
            deleteOption(option) {
                const index_of = this.options.indexOf(option)
                this.options.splice(index_of, 1)

                if(this.options_index > 0) {
                    this.options_index -= 1
                }
            },
            synchroProps(val) {
                // En fonction du type, on renvoie ou non des options possibles
                if(this.hasOptions) {
                    // On ne renvoie qu'un tableau d'options
                    const tab_values = val.filter(value => value.val != "")
                    let result = []

                    // Si pas de réponse donnée, on n'envoie rien pour notifier au parent que le form n'est pas valide
                    if(tab_values.length === 0) {
                        result = null
                    }
                    else {
                        const values = tab_values.map(value => value.val)

                        // Si on a une seule option possible, on envoie la 1ere
                        if(this.hasSingleOption) {
                            result = [values[0]]
                        }
                        // Sinon, on envoie tout
                        else {
                            result = values
                        }
                    }
                    this.$emit('update:values', result)
                }
                // Si on a un input qui n'attend pas d'option, on envoie une string vide
                else {
                    this.$emit('update:values', [""])
                }
            }
        },

        watch: {
            // Lorsque l'on renseigne une valeur sur cet input, on ajoute automatiquement une nouvelle réponse possible
            'ajout_option_text' (val) {
                if(!val) {
                    return
                }

                this.options.push({ id: this.options_index, val: val})
                this.option_to_focus = this.options_index

                this.options_index += 1
                this.ajout_option_text = ''
            },

            // On surveille le tableau pour l'emit au sync prop parent
            'options': {
                handler(val) {
                    this.synchroProps(val)
                },
                deep: true
            },
            'type' (val) {
                this.synchroProps(this.options)
            }
        },

        computed: {
            placeholderExample() {
                const trad = this.types_correspondance[this.type]

                if(trad) {
                    return this.getTrad('question.'+trad)
                }
                return this.getTrad('question.ex_reponse_str')
            },
            hasOptions() {
                return this.types_with_options.indexOf(this.type) > -1
            },
            hasSingleOption() {
                return this.types_with_single_option.indexOf(this.type) > -1
            }
        }
    }
</script>
